import React from 'react';
import ReactGA from 'react-ga';
import { Grid, Header, Container, Input, Checkbox } from 'semantic-ui-react';
import Logo from 'src/assets/images/v2/logo.svg';
import { Formik, Form, Field } from 'formik';
import map from 'lodash/map';
import { List } from 'semantic-ui-react';
import { STATES } from 'src/constants/states';
// import { ReactComponent as CalculatorIcon } from 'src/assets/icons/icon-cal-primary.svg';
import { ReactComponent as NotFoundIcon } from 'src/assets/icons/icon-not-found.svg';
import backIcon from 'src/assets/icons/back.svg';
import searchIcon from 'src/assets/icons/search.svg';
import styles from './styles.module.scss';

const States = (props: any) => {
  const _states = localStorage.getItem('states');
  let _listStates = [];
  if (_states) {
    _listStates = JSON.parse(_states);
  }

  return (
    <Container className={styles.container}>
      <Formik
        initialValues={{
          states:
            _listStates?.length > 0 ? _listStates : STATES.map(() => false),
          search: '',
        }}
        onSubmit={(values: any, { setFieldError, setSubmitting }) => {
          const selectedValue: any = [];
          localStorage.setItem('states', JSON.stringify(values.states));

          map(values.states, (value: boolean, index: number) => {
            if (value === true) {
              selectedValue.push(STATES[index - 1]);
            }
          });

          if (selectedValue.length === 0) {
            setSubmitting(false);
            setFieldError('states', 'error');
          } else {
            ReactGA.event({
              category: 'Calculator',
              action: 'States Submit',
            });

            props.goToNextStep({
              listStates: selectedValue,
            });
          }
        }}
      >
        {({ values, errors }) => {
          const filterState: any = [];
          STATES.map((item) => {
            if (
              item.title.toLowerCase().includes(values.search.toLowerCase())
            ) {
              filterState.push(item);
            }
          });
          return (
            <Form>
              <Grid centered className={styles.formContainer}>
                <Grid.Row centered columns={1} className={styles.header}>
                  <Header size="large" className={styles.title}>
                    <div>R&D Tax Calculator</div>{' '}
                    <img src={Logo} className={styles.logo} />
                  </Header>
                  <div className={styles.subTitle}>
                    What states do your employees work in? Select all that
                    apply.
                  </div>
                </Grid.Row>

                <div className={styles.states}>
                  <Grid.Row>
                    <Field name="search">
                      {({ form: { setFieldTouched, setFieldValue } }: any) => {
                        const handleChange = (event: any, value: any) => {
                          setFieldTouched('search');
                          setFieldValue('search', value.value);
                        };

                        let styleInput =
                          values['search'] !== ''
                            ? styles.inputSearchActive
                            : styles.inputSearch;
                        if (errors.states) {
                          styleInput = styles.inputSearchError;
                        }
                        return (
                          <Input
                            className={styleInput}
                            onChange={handleChange}
                            icon={
                              <img
                                src={searchIcon}
                                className={styles.searchIcon}
                              />
                            }
                            iconPosition="left"
                            placeholder="Search states"
                          />
                        );
                      }}
                    </Field>
                  </Grid.Row>
                  <Grid.Row>
                    <List divided className={styles.listContainer}>
                      {filterState.length > 0 ? (
                        filterState.map((item: any, index: number) => (
                          <Field key={index} name={`states.${item.id}`}>
                            {({
                              field,
                              form: { setFieldTouched, setFieldValue },
                            }: any) => {
                              const handleChange = (event: any, value: any) => {
                                setFieldTouched(`states.${item.id}`);
                                setFieldValue(
                                  `states.${item.id}`,
                                  value.checked
                                );
                              };
                              return (
                                <List.Item
                                  className={styles.listItem}
                                  onClick={() => {
                                    const value = field ? field.value : true;
                                    setFieldTouched(`states.${item.id}`);
                                    setFieldValue(`states.${item.id}`, !value);
                                  }}
                                >
                                  <List.Content className={styles.listContent}>
                                    <Checkbox
                                      checked={field.value}
                                      onChange={handleChange}
                                      label={item.title}
                                    />
                                  </List.Content>
                                </List.Item>
                              );
                            }}
                          </Field>
                        ))
                      ) : (
                        <div className={styles.notFound}>
                          <NotFoundIcon width={180} />
                          <div>No Matches Found</div>
                        </div>
                      )}
                    </List>
                  </Grid.Row>
                </div>

                <div style={{ display: 'flex' }}>
                  <button
                    className={styles.buttonBack}
                    type="button"
                    onClick={() => props.goToPrevStep()}
                  >
                    <img src={backIcon} /> Back
                  </button>
                  <button className={styles.buttonSubmit} type="submit">
                    Next
                  </button>
                </div>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
export default States;
