import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { Container, Grid } from 'semantic-ui-react';
import Logo from 'src/assets/images/v2/logo.svg';
import styles from './styles.module.scss';

const InputEmail = (props) => {
  const [email, setEmail] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    ReactGA.event({
      category: 'Calculator',
      action: 'Email Submit',
    });

    // here we dont want to clear utm_tags from localstorage,
    // we will clear utm_tags once user submit the email in last step
    localStorage.removeItem('expenses');
    localStorage.removeItem('states');

    props.goToNextStep({
      email,
    });
  };

  return (
    <Container className={styles.container}>
      <Grid.Row centered columns={1} className={styles.header}>
        <img src={Logo} className={styles.logo} />
      </Grid.Row>
      <div className={styles.emailContainer}>
        <div className={styles.titlePage}>
          Lastly, where can we send your detailed estimate?
        </div>

        <form name="frm-submit" onSubmit={handleSubmit}>
          <input
            className={styles.inputEmail}
            type="email"
            placeholder="Business email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <button className={styles.buttonSubmit} type="submit">
            Send My Detailed Estimate
          </button>
        </form>
      </div>

      <div className={styles.note}>
        We promise to only use your email in good faith while helping you claim
        your R&D tax credit refund.
      </div>
    </Container>
  );
};
export default InputEmail;
