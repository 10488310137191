import React, { useState } from 'react';
import ReactGA from 'react-ga';
import forEach from 'lodash/forEach';
import { Grid, Header, Input, Container } from 'semantic-ui-react';
import { Formik, Form, FieldArray, Field } from 'formik';
import { ReactComponent as IconCheck } from 'src/assets/icons/icon-check.svg';
import Logo from 'src/assets/images/v2/logo.svg';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import accounting from 'accounting';
// import { ReactComponent as CalculatorIcon } from 'src/assets/icons/icon-cal-primary.svg';
// import * as Yup from 'yup';
import styles from './styles.module.scss';

const timelineStyleContainer: any = {
  background: 'none',
  color: 'red',
  boxShadow: 'none',
  textAlign: 'left',
  padding: '0px',
};

const contentArrowStyle = {
  borderRight: 'none',
  background: 'none',
};

const iconStyle = {
  width: 10,
  height: 10,
  marginLeft: 5,
  background: '#F7CB45',
  color: '#F7CB45',
  boxShadow: 'none',
};
const iconStyleActive = {
  width: 10,
  height: 10,
  position: 'absolute',
  left: '-30px',
  top: '5px',
  borderRadius: '15px',
  lineHeight: '15px',
  textAlign: 'center',
  background: '#696DEC',
  color: '#696DEC',
  boxShadow: 'none',
} as React.CSSProperties;
const iconStyleSelected = {
  width: 20,
  height: 20,
  position: 'absolute',
  left: '-34px',
  top: '0px',
  borderRadius: '20px',
  lineHeight: '20px',
  textAlign: 'center',
  background: '#696DEC',
  color: '#696DEC',
  boxShadow: 'none',
} as React.CSSProperties;
const timelineStyle = {
  width: 2,
  height: 65,
  position: 'absolute',
  background: '#696DEC',
  top: 15,
  left: -26,
} as React.CSSProperties;

const Expenses = (props: any) => {
  const years = [2023, 2022, 2021, 2020, 2019];
  // const firstInputRef = useRef((null as unknown) as Input);
  // const [isRefSet, setIsRefSet] = useState(false);
  const [activeField, setActiveField] = useState('');
  const _expense = localStorage.getItem('expenses');
  const _listExpense: any = [];
  if (_expense) {
    const _expenseData = JSON.parse(_expense);
    forEach(_expenseData, (item) =>
      _listExpense.push(accounting.unformat(item))
    );
  }

  // useEffect(() => {
  //   if (firstInputRef.current && firstInputRef.current.focus && isRefSet) {
  //     firstInputRef.current.focus();
  //   }
  // }, [isRefSet]);

  return (
    <Container className={styles.container}>
      <Formik
        initialValues={{
          years: years,
          expenses: _listExpense.length > 0 ? _listExpense : [],
        }}
        onSubmit={(values, { setFieldError, setSubmitting }) => {
          const { expenses, years } = values;
          const listExpense: any = {};

          localStorage.setItem('expenses', JSON.stringify(expenses));

          if (values.expenses.length === 0) {
            setSubmitting(false);
            setFieldError('expenses', 'error');
          } else {
            years.map((item, index) => {
              listExpense[item.toString()] = accounting.unformat(
                expenses[index]
              );
            });

            ReactGA.event({
              category: 'Calculator',
              action: 'Expenses Submit',
            });

            props.goToNextStep({
              listExpense,
            });
          }
        }}
      >
        {({ values, errors }) => {
          const dirty =
            values.expenses.filter((itemValue: any) => itemValue != '$ ')
              .length === 0;
          const btnSubmitStyles = dirty
            ? styles.buttonSubmitDisabled
            : styles.buttonSubmit;

          return (
            <Form>
              <Grid centered className={styles.formContainer}>
                <Grid.Row centered columns={1} className={styles.header}>
                  <Header size="large" className={styles.title}>
                    <div>R&D Tax Calculator</div>{' '}
                    <img src={Logo} className={styles.logo} />
                  </Header>
                  <div className={styles.subTitle}>
                    Enter your gross expenses (wages and supplies) for the
                    following years:
                  </div>
                </Grid.Row>
                <Grid.Row className={styles.expense}>
                  <VerticalTimeline animate={false} layout={'1-column'}>
                    <FieldArray
                      name="years"
                      render={() => (
                        <div>
                          {values.years &&
                            values.years.map((item: any, index) => {
                              const inputValue = values.expenses[index];
                              const value =
                                inputValue && inputValue !== '$ '
                                  ? `$ ${accounting.formatNumber(inputValue)}`
                                  : '';
                              let styleInput =
                                inputValue && inputValue !== '$ '
                                  ? styles.inputActive
                                  : styles.input;

                              const placeHolder = 'Total yearly expenses';

                              if (index === 0 && errors.expenses) {
                                styleInput = styles.inputError;
                              }
                              const isActive =
                                activeField === `expenses.${index}`;

                              return (
                                <VerticalTimelineElement
                                  key={index}
                                  position={'right'}
                                  contentStyle={timelineStyleContainer}
                                  contentArrowStyle={contentArrowStyle}
                                  dateClassName={styles.dateStyle}
                                  date={item}
                                  iconStyle={iconStyle}
                                >
                                  <Field>
                                    {({
                                      form: { setFieldTouched, setFieldValue },
                                    }: any) => {
                                      const handleChange = (
                                        event: any,
                                        value: any
                                      ) => {
                                        setFieldTouched(`expenses.${index}`);
                                        setFieldValue(
                                          `expenses.${index}`,
                                          value.value
                                        );
                                      };
                                      const showTimeline =
                                        index < 4 &&
                                        values.expenses[index] &&
                                        values.expenses[index] != '$ ' &&
                                        values.expenses[index + 1] &&
                                        values.expenses[index + 1] != '$ ';

                                      return (
                                        <>
                                          {value !== '' && (
                                            <div
                                              style={
                                                isActive
                                                  ? iconStyleActive
                                                  : iconStyleSelected
                                              }
                                            >
                                              {!isActive && (
                                                <IconCheck width={10} />
                                              )}
                                            </div>
                                          )}
                                          {showTimeline && (
                                            <div style={timelineStyle}>
                                              &nbsp;
                                            </div>
                                          )}
                                          <Input
                                            className={styleInput}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            value={value}
                                            name={`expenses.${index}`}
                                            type="tel"
                                            placeholder={placeHolder}
                                            id={`rd_year_${item}`}
                                            onFocus={(e: any) =>
                                              setActiveField(e.target.name)
                                            }
                                            onBlur={() => setActiveField('')}
                                          />

                                          {values.years.length ===
                                            index + 1 && (
                                            <div className={styles.footerNote}>
                                              For tax year 2023, simply enter an
                                              estimate of total expenses for
                                              this tax year.
                                            </div>
                                          )}
                                        </>
                                      );
                                    }}
                                  </Field>
                                </VerticalTimelineElement>
                              );
                            })}
                        </div>
                      )}
                    />
                  </VerticalTimeline>
                </Grid.Row>
                <button
                  disabled={dirty}
                  className={btnSubmitStyles}
                  type="submit"
                >
                  Next
                </button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
export default Expenses;
