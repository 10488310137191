import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { Grid, Header, Container } from 'semantic-ui-react';
import Logo from 'src/assets/images/v2/logo.svg';

import { List } from 'semantic-ui-react';
import { INDUSTRY } from '../../constants/industry';

import styles from './styles.module.scss';
import checkedIcon from 'src/assets/icons/checked.svg';
import backIcon from 'src/assets/icons/back.svg';

const Industry = (props: any) => {
  const [industry, setIndustry] = useState({
    id: '1',
    value: 0.36,
    title: 'Architecture',
  });

  const handleSubmit = () => {
    ReactGA.event({
      category: 'Calculator',
      action: 'Industry Submit',
    });
    props.goToNextStep({
      industry,
    });
  };

  return (
    <Container className={styles.container}>
      <Grid centered className={styles.formContainer}>
        <Grid.Row centered columns={1} className={styles.header}>
          <Header size="large" className={styles.title}>
            <div>R&D Tax Calculator</div>{' '}
            <img src={Logo} className={styles.logo} />
          </Header>
          <div className={styles.subTitle}>What is your primary industry?</div>
        </Grid.Row>

        <div className={styles.industry}>
          <Grid.Row>
            <List className={styles.listContainer}>
              {INDUSTRY.map((item: any, index: number) => {
                const isSelected = item.id === industry.id;
                const selectedStyle = isSelected
                  ? styles.listItemSeleted
                  : styles.listItem;

                return (
                  <List.Item
                    key={index}
                    className={selectedStyle}
                    onClick={() => setIndustry(item)}
                  >
                    <List.Content className={styles.listContent}>
                      <span className={styles.itemTitle}>{item.title}</span>

                      {isSelected && (
                        <img
                          src={checkedIcon}
                          className={styles.imageChecked}
                        />
                      )}
                    </List.Content>
                  </List.Item>
                );
              })}
            </List>
          </Grid.Row>
        </div>

        <div className={styles.buttonFooter}>
          <button
            className={styles.buttonBack}
            onClick={() => props.goToPrevStep()}
          >
            <img src={backIcon} /> Back
          </button>
          <button
            className={styles.buttonSubmit}
            type="button"
            onClick={handleSubmit}
          >
            Calculate My Reward
          </button>

          <button
            className={styles.buttonSubmitMobile}
            type="button"
            onClick={handleSubmit}
          >
            Next
          </button>
        </div>
      </Grid>
    </Container>
  );
};
export default Industry;
