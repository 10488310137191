import React, { useEffect } from 'react';
import { Grid, Header, Container } from 'semantic-ui-react';
import Logo from 'src/assets/images/v2/logo.svg';

import { calculateTaxPerYear } from 'src/helpers/calculateTax';
import ReactGA from 'react-ga';
import styles from './styles.module.scss';
function getCookie(name) {
  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = document.cookie.split(';');
  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split('=');
    /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
    if (name == cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }
  // Return null if not found
  return null;
}
const Industry = (props) => {
  useEffect(() => {
    const { listExpense, listStates, industry, email } = props.pageData || {};
    // const finalTax = calculateTax(listExpense, listStates, industry);
    const { finalTaxYear, finalTax } = calculateTaxPerYear(
      listExpense,
      listStates,
      industry
    );

    // console.log('finalTaxYear', finalTaxYear);
    // console.log('finalTax', finalTax);

    // submit data

    const utmTagStr = localStorage.getItem('utm_tags');
    let utmTags = {} as any;
    if (!!utmTagStr) {
      utmTags = JSON.parse(utmTagStr);
    }

    // Check script loaded in IFRAME or injected directly in website
    const submitter = window.self !== window.top ? window.top : window;
    const referralCode = getCookie('referral_code');
    const postData = {
      method_name: 'submit_tax_calculator',
      rest_data: {
        name_value_list: {
          data: {
            attributes: {
              referral_code: referralCode,
              email,
              state: listStates.map((item: any) => item.title).join(','),
              years: listExpense,
              industry: industry.title,
              utm_tags: [
                utmTags.utm_source,
                utmTags.utm_medium,
                utmTags.utm_campaign,
                utmTags.utm_term,
                utmTags.utm_content,
              ],
              potential_tax_credit: {
                min: Number((finalTax.rangeStartMinus || '').replace(/,/g, '')),
                max: Number((finalTax.rangeEndMinus || '').replace(/,/g, '')),
              },
              associated_entity: 'Strike Tax',
              yearly_tax_credits: finalTaxYear
                .filter((item) => item.year !== 'TOTAL')
                .map((item) => ({
                  year: item.year,
                  federal: Number((item.fedResult || '').replace(/,/g, '')),
                  state: Number((item.avg || '').replace(/,/g, '')),
                  total: Number((item.totalYear || '').replace(/,/g, '')),
                })),
            },
          },
        },
      },
    };

    console.log('postData', postData);

    if (submitter) {
      const msg = JSON.stringify({
        contact: {
          email,
        },
        years: listExpense,
        states: listStates.map((item: any) => item.title).join(','),
        industry: industry.title,
        amount: `${finalTax.rangeStartMinus} - ${finalTax.rangeEndMinus}`,
      });
      console.log('posting message to webflow ', msg);

      submitter.postMessage(msg, '*');
    }

    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');

    const requestOptions: any = {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(postData),
      redirect: 'follow',
    };

    const url = `${process.env.REACT_APP_CRM_URL}/tax-calculator`;
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        localStorage.removeItem('utm_tags');
        ReactGA.event({
          category: 'submissions',
          action: 'Form Submission',
        });

        setTimeout(() => {
          props.goToNextStep({
            finalTax,
            finalTaxYear,
          });
        }, 2000);
      })
      .catch((error) => console.log('error', error));

    // setTimeout(() => {
    //   props.goToNextStep({
    //     finalTax,
    //     finalTaxYear,
    //   });
    // }, 2000);
  }, []);

  const imageLoading =
    'https://uploads-ssl.webflow.com/5ec4c727fce92dc501a2370f/601841e4b60e510e4b8ee463_loading_600%20x%20600.gif';

  return (
    <Container className={styles.container}>
      <Grid centered className={styles.formContainer}>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <img src={Logo} className={styles.logo} />
        </div>

        <Grid.Row centered columns={1} className={styles.header}>
          <div className={styles.center}>
            <Header size="large" className={styles.title}>
              Calculating Your Estimate
            </Header>
            <div className={styles.subTitle}>
              One moment while we calculate your R&D Tax Credit estimate.
            </div>
          </div>
          <div className={styles.backButton}>&nbsp;</div>
        </Grid.Row>
        <Grid.Row className={styles.loading}>
          <img width={200} height={200} src={imageLoading} alt="loading..." />
          {/* <ReactPlayer width={200} muted autoPlay playing url={videoUrl} /> */}
        </Grid.Row>
        <Grid.Row>
          <div className={styles.footer}>
            The information provided by the STRIKE Estimator is for
            demonstrative purposes only. The accuracy and results are not
            guaranteed and are subject to change.
          </div>
        </Grid.Row>
      </Grid>
    </Container>
  );
};
export default Industry;
