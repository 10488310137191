import forEach from 'lodash/forEach';
import map from 'lodash/map';

function ro(n: number) {
  // @ts-ignore
  return (n / 1000).toFixed() * 1000;
}

function mo(n: number) {
  return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const calculateTax = (expenses: any, states: any, industry: any) => {
  const listStates: any = [];
  const valueIndustry = industry.value;
  let totalExpense = 0;

  map(expenses, (item: any) => (totalExpense += parseInt(item, 10)));
  map(states, (item: any) => listStates.push(item.value));

  const stateMin = Math.min(...listStates);
  const stateMax = Math.max(...listStates);

  const fedResult = totalExpense * valueIndustry * 0.1;

  const rangeStart = fedResult * stateMin + fedResult;
  const rangeEnd = fedResult * stateMax + fedResult;

  const rangeStartMinus = mo(ro(rangeStart - rangeStart * 0.1));
  const rangeEndMinus = mo(ro(rangeEnd + rangeEnd * 0.1));

  return { rangeStartMinus, rangeEndMinus };
};

export const deprecatedCalculateTaxPerYear = (
  expenses: any,
  states: any,
  industry: any
) => {
  const listStates: any = [];
  const valueIndustry = industry.value;

  let result: any = [];
  let totalFedResult = 0;
  let totalAvg = 0;
  let totalResult = 0;

  map(states, (item: any) => listStates.push(item.value));
  const stateMin = Math.min(...listStates);
  const stateMax = Math.max(...listStates);
  forEach(expenses, (item: any, index: number) => {
    if (item > 0) {
      const fedResult = item * valueIndustry * 0.1;
      let rangeStart = 0;
      let rangeEnd = 0;

      rangeStart = fedResult * stateMin + fedResult;
      rangeEnd = fedResult * stateMax + fedResult;

      const rangeStartMinus = rangeStart - rangeStart * 0.1;
      const rangeEndMinus = rangeEnd + rangeEnd * 0.1;

      totalFedResult += ro(rangeStartMinus);
      totalAvg += ro(rangeStart / listStates.length);
      totalResult += ro(rangeEndMinus);

      result.push({
        year: index,
        fedResult: mo(ro(rangeStartMinus)),
        avg: mo(ro(rangeStart / listStates.length)),
        totalYear: mo(ro(rangeEndMinus)),
      });
    }
  });

  result = result.reverse();
  result.push({
    year: 'TOTAL',
    fedResult: mo(totalFedResult),
    avg: mo(totalAvg),
    totalYear: mo(totalResult),
  });

  return {
    finalTaxYear: result,
    finalTax: {
      rangeStartMinus: mo(totalFedResult),
      rangeEndMinus: mo(totalResult),
    },
  };
};

export const calculateTaxPerYear = (
  expenses: any,
  states: any,
  industry: any
) => {
  const listStates: Array<number> = [];
  const valueIndustry = industry.value;

  let result: any = [];
  let totalFedResult = 0;
  let totalAvg = 0;
  let totalResult = 0;

  map(states, (item: any) => listStates.push(item.value));
  forEach(expenses, (item: any, index: number) => {
    if (item > 0) {
      const fedResult = item * valueIndustry * 0.1;
      const fedResultFinal = Math.round(fedResult);
      const totalState = (listStates || []).reduce((acc, stateVal) => {
        return acc + fedResultFinal * stateVal;
      }, 0);
      const avgState = Math.round(totalState / listStates.length);
      const total = fedResultFinal + avgState;

      totalFedResult += fedResultFinal;
      totalAvg += avgState;
      totalResult += total;

      result.push({
        year: index,
        fedResult: mo(fedResultFinal),
        avg: mo(avgState),
        totalYear: mo(total),
      });
    }
  });

  result = result.reverse();
  result.push({
    year: 'TOTAL',
    fedResult: mo(totalFedResult),
    avg: mo(totalAvg),
    totalYear: mo(totalResult),
  });

  const rangeStart = totalResult - totalResult * 0.1;
  const rangeEnd = totalResult + totalResult * 0.1;

  return {
    finalTaxYear: result,
    finalTax: {
      rangeStartMinus: mo(Math.round(rangeStart)),
      rangeEndMinus: mo(Math.round(rangeEnd)),
    },
  };
};
