// import { lazy } from 'react';
// import States from 'src/pages/States';
// import Industry from 'src/pages/Industry';
// import Loading from 'src/pages/Loading';
// import Result from 'src/pages/Result';
// import Thanks from 'src/pages/Thanks';
import Main from 'src/pages/Main';
// const Main = lazy(() => import(/* webpackPreload: true */ 'src/pages/Main'));

const routes = [
  {
    path: [
      '/',
      '/calculator',
      '/calculator/embed',
      '/usa-tax-credit/*',
      '/industries/*',
      '/calc-test-page',
      '/payroll-tax',
      '/industry-rd-tax-credits/*',
      '/sub-industries/*',
      '/partners',
      '/partners/*',
      '/lp/*',
      '/lp/claim-r-d-credit',
      '/case-study/*',
    ],
    exact: true,
    component: Main,
  },
  // {
  //   path: '/states',
  //   exact: true,
  //   component: States,
  // },
  // {
  //   path: '/industry',
  //   exact: true,
  //   component: Industry,
  // },
  // {
  //   path: '/loading',
  //   exact: true,
  //   component: Loading,
  // },
  // {
  //   path: '/result',
  //   exact: true,
  //   component: Result,
  // },
  // {
  //   path: '/thanks',
  //   exact: true,
  //   component: Thanks,
  // },
];

export default routes;
