export const INDUSTRY = [
  { id: '1', value: 0.359914571205166, title: 'Architecture' },
  { id: '2', value: 0.22, title: 'Agriculture' },
  { id: '3', value: 0.576130715835985, title: 'Chemical/Pharmaceutical' },
  // { id: '4', value: 1.0, title: 'Client-Provided QREs (no haircut)' },
  { id: '5', value: 0.254500494409702, title: 'Engineering' },
  { id: '6', value: 0.57115627552481, title: 'General Contractor' },
  { id: '7', value: 0.284107469502407, title: 'Manufacturing' },
  { id: '8', value: 0.559306664627778, title: 'Software' },
  { id: '9', value: 0.581000075878291, title: 'Tool/Die' },
  { id: '10', value: 0.184681804902921, title: 'Winery/Brewery' },
  { id: '11', value: 0.268, title: 'Industrial Hemp & CBD' },
];
