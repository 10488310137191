import React from 'react';
import { Grid, Header, Container } from 'semantic-ui-react';
import { Formik, Form } from 'formik';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  // TwitterIcon,
  // FacebookIcon,
  // LinkedinIcon,
} from 'react-share';
import ReactTooltip from 'react-tooltip';
import Logo from 'src/assets/images/v2/logo.svg';
// import { ReactComponent as EmailShare } from 'src/assets/icons/email.svg';
// import { ReactComponent as Link } from 'src/assets/icons/url-link.svg';
import styles from './styles.module.scss';
import copyIcon from 'src/assets/icons/copyLink.svg';
import backIcon from 'src/assets/icons/back.svg';

import fbIcon from 'src/assets/images/v2/facebook.svg';
import twIcon from 'src/assets/images/v2/twitter.svg';
import linkedIcon from 'src/assets/images/v2/linked.svg';
import linkIcon from 'src/assets/images/v2/link.svg';
import EmailIcon from 'src/assets/images/v2/email.svg';
import CallMeIcon from 'src/assets/images/v2/callme.svg';

const Thanks = (props: any) => {
  const { phoneMethod } = props.pageData || {};

  const shareUrl = 'https://www.striketax.com/calculator';
  const shareTitle =
    'Check out this R&D Tax Credit Calculator I found on StrikeTax.com.';
  const copyToClipBoard = async (copyMe = '') => {
    try {
      await navigator.clipboard.writeText(copyMe);
      // alert('Copied!');
    } catch (err) {
      console.log('Copy fail');
    }
  };
  return (
    <>
      <Container className={styles.container}>
        <Formik
          initialValues={{}}
          onSubmit={() => {
            return;
          }}
        >
          {() => (
            <Form>
              <Grid centered className={styles.formContainer}>
                <Grid.Row centered columns={1} className={styles.header}>
                  <div
                    className={styles.backButton}
                    onClick={() => props.goToInitStep()}
                  >
                    <img src={backIcon} className={styles.icon} />
                    <span className={styles.backText}>Restart Calculator</span>
                  </div>
                  <img src={Logo} className={styles.logo} />
                </Grid.Row>
                <Grid.Row className={styles.subTitleSection}>
                  <Header size="large" className={styles.subTitle}>
                    <span className={styles.subTitleShare}>Thank you!</span>
                    <br />
                    <span className={styles.subTitleShareText}>
                      {['I’ll Call You'].includes(phoneMethod)
                        ? 'Your information and contact preference has been sent to our team. We’ll be waiting for your call!'
                        : 'Your information and contact preference has been sent to our team. You’ll receive a call or text shortly!'}
                    </span>
                  </Header>
                </Grid.Row>

                {['I’ll Call You'].includes(phoneMethod) && (
                  <Grid.Row>
                    <Grid.Column
                      mobile={16}
                      tablet={16}
                      largeScreen={16}
                      widescreen={16}
                      computer={16}
                    >
                      <a href="tel:(208) 252-5444">
                        <img src={CallMeIcon} style={{ width: '100%' }} />
                      </a>
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
        <div className={styles.ShareContainer}>
          <div className={styles.heading}>Found this calculator helpful?</div>
          <div className={styles.subHeading}>
            SHARe IT WITH someone who needs it.
          </div>

          <div className={styles.shareWrapper} style={{ margin: 'auto' }}>
            <div className={styles.linkContainerRectangle}>
              <button
                value="CopyUrl"
                type="button"
                onClick={() => copyToClipBoard(shareUrl)}
                className={styles.copySvgIconButton}
              >
                <ReactTooltip
                  id="linkCopyInput"
                  type="light"
                  event="click"
                  place="left"
                  effect="solid"
                  delayHide={2000}
                  afterShow={() => {
                    copyToClipBoard(shareUrl);
                    ReactTooltip.hide();
                  }}
                >
                  <span>Link Copied</span>
                </ReactTooltip>
                <img
                  src={copyIcon}
                  data-tip
                  data-for="linkCopyInput"
                  className={styles.copySvgIcon}
                />
              </button>
              <span className={styles.shareUrlText}>
                www.striketax.com/calculator
              </span>
            </div>
            <div className={styles.form}>
              <div className={styles.shareButton}>
                <FacebookShareButton url={shareUrl} quote={shareTitle}>
                  {/* <FacebookIcon size={48} round={true} /> */}
                  <img src={fbIcon} />
                </FacebookShareButton>
              </div>
              <div className={styles.shareButton}>
                <TwitterShareButton url={shareUrl} title={shareTitle}>
                  {/* <TwitterIcon size={48} round={true} /> */}
                  <img src={twIcon} />
                </TwitterShareButton>
              </div>
              <div className={styles.shareButton}>
                <LinkedinShareButton
                  url={shareUrl}
                  title={shareTitle}
                  summary={shareTitle}
                >
                  {/* <LinkedinIcon size={48} round={true} /> */}
                  <img src={linkedIcon} />
                </LinkedinShareButton>
              </div>
              <div className={styles.shareButton}>
                <EmailShareButton url={shareUrl} body={shareTitle}>
                  <img src={EmailIcon} />
                </EmailShareButton>
              </div>
              <div className={styles.shareButton}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => copyToClipBoard(shareUrl)}
                >
                  <ReactTooltip
                    id="linkCopyShare"
                    type="light"
                    event="click"
                    place="bottom"
                    effect="solid"
                    delayHide={2000}
                    afterShow={() => {
                      copyToClipBoard(shareUrl);
                      ReactTooltip.hide();
                    }}
                  >
                    <span>Link Copied</span>
                  </ReactTooltip>
                  <div
                    className={styles.hyperlinkButton}
                    data-tip
                    data-for="linkCopyShare"
                  >
                    {/* <Link width={22.47} /> */}
                    <img src={linkIcon} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
export default Thanks;
