import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'src/pages';
import ReactGA from 'react-ga';
import * as serviceWorker from 'src/serviceWorker';
import { Provider as ThemeProvider } from 'src/helpers/ThemeContext';
import themes from 'src/styles/theme';
import 'src/assets/images/loading.mp4';

ReactGA.initialize('UA-156258102-2');
ReactDOM.render(
  <ThemeProvider value={themes.light}>
    <Root />
  </ThemeProvider>,
  document.getElementById('strike-tax-tool')
);
serviceWorker.unregister();
