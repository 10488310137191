import * as React from 'react';
import Expense from 'src/pages/Expenses';
import States from 'src/pages/States';
import Industry from 'src/pages/Industry';
import Loading from 'src/pages/Loading';
import Result from 'src/pages/Result';
import Thanks from 'src/pages/Thanks';
import InputEmail from 'src/pages/InputEmail';

const steps = [Expense, States, Industry, InputEmail, Loading, Result, Thanks];

const Main = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [pageData, setPageData] = React.useState({});

  const Component = steps[activeStep] || steps[0];

  return (
    <Component
      {...props}
      goToNextStep={(data) => {
        setPageData((prev) => ({
          ...prev,
          ...data,
        }));
        if (activeStep < steps.length - 1) {
          setActiveStep(activeStep + 1);
        }
      }}
      goToPrevStep={() => {
        if (activeStep > 0) {
          setActiveStep(activeStep - 1);
        }
      }}
      goToInitStep={() => {
        setActiveStep(0);
      }}
      pageData={pageData}
    />
  );
};

export default Main;
