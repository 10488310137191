import React, { useState } from 'react';

import numeral from 'numeral';
import { Grid, Container } from 'semantic-ui-react';
import Logo from 'src/assets/images/v2/logo.svg';

import backIcon from 'src/assets/icons/back.svg';
import copyIcon from 'src/assets/icons/copyLink.svg';
import checkedIcon from 'src/assets/images/v2/checked.svg';
import uncheckIcon from 'src/assets/images/v2/uncheck.svg';
import fbIcon from 'src/assets/images/v2/facebook.svg';
import twIcon from 'src/assets/images/v2/twitter.svg';
import linkedIcon from 'src/assets/images/v2/linked.svg';
import linkIcon from 'src/assets/images/v2/link.svg';
import EmailIcon from 'src/assets/images/v2/email.svg';

import styles from './styles.module.scss';
// import cs from 'classnames';
import ReactTooltip from 'react-tooltip';
// import { ReactComponent as EmailShare } from 'src/assets/icons/email.svg';
// import { ReactComponent as Link } from 'src/assets/icons/url-link.svg';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  // TwitterIcon,
  // FacebookIcon,
  // LinkedinIcon,
} from 'react-share';

const Result = (props: any) => {
  const { finalTax, listExpense, listStates, industry, email } =
    props.pageData || {};

  const [phone, setPhone] = useState('');
  const [mode, setMode] = useState('Text Me');

  // console.log('props.pageData', props.pageData);

  const shareUrl = 'https://www.striketax.com/calculator';
  const shareTitle =
    'Check out this R&D Tax Credit Calculator I found on StrikeTax.com.';

  const copyToClipBoard = async (copyMe = '') => {
    try {
      await navigator.clipboard.writeText(copyMe);
      // alert('Copied!');
    } catch (err) {
      console.log('Copy fail');
    }
  };

  const formatNum = (num) => {
    return numeral(num).format('0.0a');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!phone) return;

    // Check script loaded in IFRAME or injected directly in website
    const submitter = window.self !== window.top ? window.top : window;
    if (submitter) {
      const msg = JSON.stringify({
        contact: {
          email,
          phone,
          phone_method: mode,
        },
        years: listExpense,
        states: listStates.map((item: any) => item.title).join(','),
        industry: industry.title,
        amount: `${finalTax.rangeStartMinus} - ${finalTax.rangeEndMinus}`,
      });
      console.log('posting message to webflow ', msg);

      submitter.postMessage(msg, '*');
    }

    props.goToNextStep({
      phoneNumber: phone,
      phoneMethod: mode,
    });
  };

  return (
    <Container className={styles.container}>
      <form onSubmit={handleSubmit}>
        <Grid centered className={styles.formContainer}>
          <Grid.Row centered columns={1} className={styles.header}>
            <div
              className={styles.backButton}
              onClick={() => props.goToInitStep()}
            >
              <img src={backIcon} className={styles.icon} />
              <span className={styles.backText}>Restart Calculator</span>
            </div>
            <img src={Logo} className={styles.logo} />
          </Grid.Row>

          <Grid.Row>
            <Grid.Column className={styles.estimateBox}>
              <div className={styles.heading}>We’ve emailed your estimate!</div>
              <div className={styles.subHeading}>YOUR ESTIMATED REWARD:</div>
              <div className={styles.numbers}>
                <div className={styles.money}>
                  <sup>$</sup>
                  {formatNum(finalTax.rangeStartMinus)}
                </div>
                <div className={styles.text}>to</div>
                <div className={styles.money}>
                  <sup>$</sup>
                  {formatNum(finalTax.rangeEndMinus)}
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>

          <div className={styles.enterPhone}>
            <div>
              <div className={styles.heading}>
                Speak with us to claim your credit
              </div>

              <div>
                <input
                  required
                  className={styles.inputPhone}
                  value={phone}
                  type="tel"
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter your Phone Number"
                />
              </div>

              <div>
                <div className={styles.modeText}>Select Method</div>
                <ul>
                  {['Text Me', 'Call Me', 'I’ll Call You'].map((m, index) => {
                    return (
                      <li key={index} onClick={() => setMode(m)}>
                        {mode === m ? (
                          <img src={checkedIcon} />
                        ) : (
                          <img src={uncheckIcon} />
                        )}
                        {m}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div>
                <button type="submit" className={styles.buttonSubmit}>
                  Submit
                </button>

                <div className={styles.notes}>
                  We promise to only use your phone number in good faith while
                  helping you claim your R&D tax credit refund.
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </form>

      <div className={styles.ShareContainer}>
        <div className={styles.heading}>Found this calculator helpful?</div>
        <div className={styles.subHeading}>
          SHARe IT WITH someone who needs it.
        </div>

        <div className={styles.shareWrapper} style={{ margin: 'auto' }}>
          <div className={styles.linkContainerRectangle}>
            <button
              value="CopyUrl"
              type="button"
              onClick={() => copyToClipBoard(shareUrl)}
              className={styles.copySvgIconButton}
            >
              <ReactTooltip
                id="linkCopyInput"
                type="light"
                event="click"
                place="left"
                effect="solid"
                delayHide={2000}
                afterShow={() => {
                  copyToClipBoard(shareUrl);
                  ReactTooltip.hide();
                }}
              >
                <span>Link Copied</span>
              </ReactTooltip>
              <img
                src={copyIcon}
                data-tip
                data-for="linkCopyInput"
                className={styles.copySvgIcon}
              />
            </button>
            <span className={styles.shareUrlText}>
              www.striketax.com/calculator
            </span>
          </div>
          <div className={styles.form}>
            <div className={styles.shareButton}>
              <FacebookShareButton url={shareUrl} quote={shareTitle}>
                {/* <FacebookIcon size={48} round={true} /> */}
                <img src={fbIcon} />
              </FacebookShareButton>
            </div>
            <div className={styles.shareButton}>
              <TwitterShareButton url={shareUrl} title={shareTitle}>
                {/* <TwitterIcon size={48} round={true} /> */}
                <img src={twIcon} />
              </TwitterShareButton>
            </div>
            <div className={styles.shareButton}>
              <LinkedinShareButton
                url={shareUrl}
                title={shareTitle}
                summary={shareTitle}
              >
                {/* <LinkedinIcon size={48} round={true} /> */}
                <img src={linkedIcon} />
              </LinkedinShareButton>
            </div>
            <div className={styles.shareButton}>
              <EmailShareButton url={shareUrl} body={shareTitle}>
                <img src={EmailIcon} />
              </EmailShareButton>
            </div>
            <div className={styles.shareButton}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => copyToClipBoard(shareUrl)}
              >
                <ReactTooltip
                  id="linkCopyShare"
                  type="light"
                  event="click"
                  place="bottom"
                  effect="solid"
                  delayHide={2000}
                  afterShow={() => {
                    copyToClipBoard(shareUrl);
                    ReactTooltip.hide();
                  }}
                >
                  <span>Link Copied</span>
                </ReactTooltip>
                <div
                  className={styles.hyperlinkButton}
                  data-tip
                  data-for="linkCopyShare"
                >
                  {/* <Link width={22.47} /> */}
                  <img src={linkIcon} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Result;
